import React from 'react';
import { View, Theme, Text } from '@/app';
import { Page } from '@/shared';
import { variantProvider } from '@/app/theme';
import licenses  from '@/app/license.json'

const LicenseItem = ({name, info}) => {
  const type = 'License type(s): ' + info.licenses
  const repo = 'Online repository: ' + info.repository
  const url = 'License URL: ' + info.licenseUrl
  return (
    <View  component='li' style={styles.itemWrapper} variants={['column']}>
      <Text text={name} variants={['p1']}/>
      <Text text={type} variants={['p3', 'marginTop:1', 'marginLeft:2']}/>
      <Text text={repo} variants={['p3', 'marginTop:1', 'marginLeft:2']}/>
      <Text text={url} variants={['p3', 'marginTop:1', 'marginLeft:2']}/>
    </View>
  )
}

const Licenses: React.FC = () => {
  
  return (
    <Page variants={['column']}>
      <Text text={'Licenses'} variants={['h2']}/>
      <View component='ul' variants={['column']}>
        {Object.entries(licenses)?.map(([name, info]) => (
          <LicenseItem key={name} name={name} info={info} />
        ))}
      </View>
    </Page>
  );
};

const styles = variantProvider.createComponentStyle({
  wrapper: {
    minHeight: '100vh',
    ...Theme.presets.center,
    ...Theme.presets.flex,
    flexDirection: 'column',
    ...Theme.spacing.marginVertical(6),
  },
  content: {
    display: 'flex',
    width: 'auto',
    flexDirection: 'column',
  },
  itemWrapper: {
    paddingTop: Theme.spacing.value(2),
    marginBottom: Theme.spacing.value(2),
  },
}, true);

export default Licenses;
